<template>
  <div>
    <b-pagination v-model="paging.current" :total-rows="paging.total" :per-page="paging.size" limit="10" />

    <b-overlay :show="loading">
      <table class="table table-sm">
        <thead>
          <tr>
            <th width="200px">Timestamp</th>
            <th width="170px">User</th>
            <th width="160px">Result</th>
            <th>Calling</th>
            <th>Called</th>
          </tr>
        </thead>
        <tbody v-if="log">
          <tr v-for="(e,i) in log" :key="`LE_${i}`">
            <td>{{formatTimestamp(e.timestamp)}}</td>
            <td>{{e.user}}</td>
            <td>
              <icon-text :icon="e.reply === 'Access-Accept' ? 'check' : 'times'">{{e.reply}}</icon-text>
            </td>
            <td>{{e.calling}}</td>
            <td>{{e.called}}</td>
          </tr>
        </tbody>
      </table>
    </b-overlay>
  </div>
</template>

<script>
import IconText from '../../../components/IconText'

export default {
  components: { IconText },
  data: () => ({
    log: null,
    paging: {
      total: 0,
      current: 1,
      size: 30
    },
    loading: false
  }),
  watch: {
    'paging.current': function () { this.update() }
  },
  methods: {
    formatTimestamp (d) {
      const dateObject = new Date(d)
      return `${dateObject.toLocaleDateString('en-GB')} ${dateObject.toLocaleTimeString('en-GB')}`
    },
    async update () {
      this.loading = true
      try {
        this.log = await API.logs.get_auth((this.paging.current - 1) * this.paging.size, this.paging.size)
      } catch (error) {
        alert(error)
      } finally {
        this.loading = false
      }
    },
    async reload () {
      try {
        this.paging.total = await API.logs.count_auth()
        this.update()
      } catch (error) {
        alert(error)
      }
    }
  },
  mounted () {
    this.reload()
  }
}
</script>
